import React from 'react'
import './NavButton.css'

const NavButton = ({text, value}) => {
    const scroll = () => {
        window.scrollTo({
        top: document.documentElement.getBoundingClientRect().width*value,
        behavior: "smooth" 
    })
    }
    return (
        <div>
            <button variant = "text" className='button' onClick = {scroll}>{text}</button>
        </div>
    )
}

export default NavButton