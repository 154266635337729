import React from 'react'
import './GooglePlayButton.css'

const GooglePlayButton = () => {
    const onClick = () => {
        console.log("link")
    }
    
    return (
        <div>
            <a href = "https://play.google.com/store/games?hl=en_US&gl=US">
                <button className='GooglePlayButton' ></button>
            </a>
        </div>
    )
}

export default GooglePlayButton