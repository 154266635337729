import React from 'react'
import './Values.css'
import faqhead from '../../assets/faqs.svg'
import swirl from '../../assets/swirl.svg'
import semi4 from '../../assets/semi4.svg'
import { Accordion } from '../../components'

const Values = () => {
    return (
        <div>
            <img src = {faqhead} className = "faq"></img>
            <div className='Values'>
                <Accordion />
            </div>
            <img src = {semi4} className = "semi4"></img>

            <img src = {swirl} className = "swirltwo"></img>

            
        </div>
    )
}

export default Values