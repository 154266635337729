import React from 'react'
import { NavButton } from '../../components'
import './NavBar.css'
import test from '../../assets/upbutton.svg'

const NavBar = () => {
    const scrolltop = () => {
        window.scrollTo({
        top: 0,
        behavior: "smooth" 
    })
    }
    return (
        <div>
        <div className='NavBar'>
            <NavButton text = "About" value = "0.85"/>
            <NavButton text = "Process" value = "2.75"/>
            <NavButton text = "Values" value = "3.28"/>
            <NavButton text = "Testimonials" value = "4.08"/>
            <NavButton text = "Services" value = "4.78"/>
            <NavButton text = "Download" value = "5.49"/>
        </div>
        <div>
            <button variant = "image" className='topbutton' onClick = {scrolltop}></button>
        </div>
        </div>
    )
}

export default NavBar