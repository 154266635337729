import React from 'react'
import './PlayStore.css'
import Play from '../../assets/google-play 1.svg'


const PlayStore = () => {
    const onClick = () => {
        console.log("link")
    }
    
    return (
        <div>
            <a href = "https://play.google.com/store/games?hl=en_US&gl=US">
                
                <button className='playButton' >
                    <img src = {Play} className='Play'></img>
                    <h2 className='playText'>Google Play</h2>
                </button>
            </a>
        </div>
    )
}

export default PlayStore