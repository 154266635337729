import React from 'react'
import './AppStore.css'
import Apple from '../../assets/apple-logo 1.svg'


const AppStore = () => {
    const onClick = () => {
        console.log("link")
    }
    
    return (
        <div>
            <a href = "https://www.apple.com/app-store/">
                
                <button className='storeButton' >
                    <img src = {Apple} className='Apple'></img>
                    <h2 className='storeText'>App Store</h2>
                </button>
            </a>
        </div>
    )
}

export default AppStore