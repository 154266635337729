import React from 'react'
import './Services.css'
import ServicesCont from '../../assets/ServicesCont.svg'

const Services = () => {
    return (
        <div>
            <img src = {ServicesCont} className = "ServicesCont"></img>
        </div>
    )}

    export default Services