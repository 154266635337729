import React from 'react'
import './SocialMedia.css'
import social from '../../assets/social.svg'

const SocialMedia = () => {
    return (
        <div className='SocialMedia'>
            <a href = "https://instagram.com">
                <button className='SmB'></button>
            </a> 
            <img src = {social} className = "social"></img>
        </div>
    )
}

export default SocialMedia