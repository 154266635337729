import React from 'react'
import { LearnMoreButton } from '../../components'
import './LearnMore.css'

const LearnMore = () => {
    return (
        <div className='LearnMore'>
            <div className = "one">
                <LearnMoreButton />
            </div>
            <div className = "two">
                <LearnMoreButton />
            </div>
            <div className = "three">
                <LearnMoreButton />
            </div>
        </div>
    )               
}

export default LearnMore