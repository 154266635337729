import React from 'react'
import { Logo } from '../../components'
import {NavBar, SocialMedia} from '../../containers'
import './Home.css'
import header from '../../assets/header.svg'
import baseOne from '../../assets/Base1c.png'
import baseTwo from '../../assets/Base2c.png'
import baseThree from '../../assets/Base3c.png'
import semiOne from '../../assets/semi1.svg'
import title from '../../assets/Title.svg'
import pad from '../../assets/pad.svg'


const Home = () => {
    return (
        <div className='Home'>
            <div className='Rectangle'></div>
            <NavBar/>
            <Logo/>
            <SocialMedia/>
            <div>
                <img src = {title} className = "Title" ></img>
            </div>
            <div>
                <div className='Line One'></div>
                <div className='Line Two'></div>
            </div>
            <div className='ImgContainer'>
                <img src = {baseTwo} className = "BaseTwo" ></img>
                <img src = {baseThree} className = "BaseThree" ></img>
                <img src = {baseOne} className = "BaseOne" ></img>
            </div>
            <img src = {semiOne} className = "semiOne" ></img>
            <img src = {pad} className = "pad"></img>

        </div>
    )
}

export default Home