import React from 'react'
import './LearnMoreButton.css'

const LearnMoreButton = () => {
    const onClick = () => {
        console.log("link")
    }
    
    return (
        <div>
            <a href = "https://issakaai.com">
                <button variant = "text" className='learnButton' >Learn More</button>
            </a>
        </div>
    )
}

export default LearnMoreButton