import React from 'react'
import './Accordion.css'
import plus from './../../assets/plus.svg'

const Accordion = () => {   
    return (
      <div class="row">
        <div class="colOne">
          <div class="tabs">
            <div class="tab">
              <input type="radio" id="rd1" name="rd"/>
              <label class="tab-label" for="rd1">
              <div className="label-content">Coherence</div>
              <img src = {plus} className = "plusSign" ></img>
              </label>
              <div class="tab-content">
                Base has an intuitive user interface design thereby user-friendly. Users are able to navigate through the app and locate what they are looking for within a few clicks using the most efficient routes. Our error-free functionality, quick onboarding, short response time and fast loading speed all contribute to the coherence experience. 
              </div>
            </div>
            <div className = "gap"></div>
            <div class="tab">
              <input type="radio" id="rd2" name="rd"/>
              <label class="tab-label" for="rd2">
              <div className="label-content">Privacy</div>
              <img src = {plus} className = "plusSign" ></img>
              </label>
              <div class="tab-content">
                Unlike most mobile apps, Base does not collect or leverage any user data beyond necessary login credentials and will certainly not sell user data to any third parties. Base’s users have the right to be forgotten. Thus, every time they delete data such as browsing and search history, location, contacts, and other sensitive information, or completely deleting their accounts, it is completely wiped from our databases.
              </div>
            </div>
            <div className = "gap"></div>
            <div class="tab">
              <input type="radio" id="rd3" name="rd"/>
              <label class="tab-label" for="rd3">
              <div className="label-content">Community</div>
              <img src = {plus} className = "plusSign" ></img>
              </label>
              <div class="tab-content">
                Individual as well as group-chat functionalities enable users to stay fully connected and engaged with your organization, and become active members of your online community. 
              </div>
            </div>
            <div className = "gap"></div>
            <div class="tab">
              <input type="radio" id="rd4" name="rd"/>
              <label class="tab-label" for="rd4">
              <div className="label-content">Responsibility</div>
              <img src = {plus} className = "plusSign" ></img>
              </label>
              <div class="tab-content">
                The organization will have full control over its spaces. You can actively manage the content and discussions in your spaces to make sure users do not send or receive inappropriate or derogatory messages. 
              </div>
            </div>
            <div className = "gap"></div>
            <div class="tab">
              <input type="radio" id="rd5" name="rd"/>
              <label for="rd5" class="tab-close"> &times;</label>
            </div>
          </div>
        </div>
        <div class="colTwo">
          <div class="tabs">
            <div class="tab">
              <input type="radio" id="rd6" name="rd"/>
              <label class="tab-label" for="rd6">
              <div className="label-content">Accessibility</div>
              <img src = {plus} className = "plusSign" ></img>
              </label>
              <div class="tab-content">
                Base is optimized for minimal hardware and internet requirements. This is particularly helpful if your organization targets a less privileged audience with limited access to phones and/or the internet. 
              </div>
            </div>
            <div className = "gap"></div>
            <div class="tab">
              <input type="radio" id="rd7" name="rd"/>
              <label class="tab-label" for="rd7">
              <div className="label-content">Information</div>
              <img src = {plus} className = "plusSign" ></img>
              </label>
              <div class="tab-content">
                With the discussion forum in Base, organizations are able to make posts to share resources and information with all users.
              </div>
            </div>
            <div className = "gap"></div>
            <div class="tab">
              <input type="radio" id="rd8" name="rd"/>
              <label class="tab-label" for="rd8">
              <div className="label-content">Multilingual</div>
              <img src = {plus} className = "plusSign" ></img>
              </label>
              <div class="tab-content">
                All conversations in Base can be translated into 150 languages.
              </div>
            </div>
            <div className = "gap"></div>
            <div class="tab">
              <input type="radio" id="rd9" name="rd"/>
              <label class="tab-label" for="rd9">
              <div className="label-content">Technologies</div>

              <img src = {plus} className = "plusSign" ></img>
              </label>
              <div class="tab-content">
                Beyond the basic functionalities and personalizable interface, our designers are actively developing useful tools that fit your organization’s specific needs.
              </div>
            </div>
            <div className = "gap"></div>
            <div class="tab">
              <input type="radio" id="rd10" name="rd"/>
              <label for="rd10" class="tab-close"> &times;</label>
            </div>
          </div>
        </div>
      </div>

    )
}

export default Accordion