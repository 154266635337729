import React from 'react'
import './AboutUs.css'
import title from '../../assets/Aboutus.svg'
import { BackgroundBox } from '../../components'
import RectOne from '../../assets/RectangleOne.svg'
import RectBlue from '../../assets/RectangleBlue.svg'
import baseOne from '../../assets/Base1c.png'
import baseTwo from '../../assets/Base2c.png'
import baseThree from '../../assets/Base3c.png'
import Services from '../../assets/Services.svg'
import Works from '../../assets/Works.svg'
import Choose from '../../assets/Choose.svg'
import SemiTwo from '../../assets/SemiTwo.svg'
import swirl from '../../assets/swirl.svg'




const AboutUs = () => {
    return (
        <div className='AboutUs'>
            <img src = {title} className = "title"></img>
            <div>
                <img src = {RectOne} className = "RectOne"></img>
                <img src = {RectOne} className = "RectTwo"></img>
                <img src = {RectBlue} className = "RectThree"></img>
            </div>
            <div>
                <img src = {baseTwo} className = "phoneTwo" ></img>
                <img src = {baseThree} className = "phoneThree" ></img>
                <img src = {baseOne} className = "phoneOne" ></img>
            </div>
            <div>
                <img src = {Services} className = "Services" ></img>
                <img src = {Works} className = "Works" ></img>
                <img src = {Choose} className = "Choose" ></img>
            </div>
            <div>
                <img src = {SemiTwo} className = "SemiTwo" ></img>
                <img src = {swirl} className = "swirl" ></img>
            </div>
        </div>
        
    )
}

export default AboutUs