import React from 'react'
import './Footer.css'
import Footerimg from '../../assets/Footerimg.svg'

const Footer = () => {
    return (
        <div>
            <img src = {Footerimg} className = "Footerimg"></img>
        </div>
    )}

    export default Footer