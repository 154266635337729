import React from 'react'
import './SubscribeButton.css'

const SubscribeButton = () => {
    const onClick = () => {
        console.log("link")
    }
    
    return (
        <div>
            <a href = "https://issakaai.com">
                <button variant = "text" className='subscribeButton' >Subscribe</button>
            </a>
        </div>
    )
}

export default SubscribeButton