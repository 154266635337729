import React from 'react'
import './Download.css'
import downloadbase from '../../assets/downloadbase.svg'
import baseThree from '../../assets/Base3c.png'
import subscribe from '../../assets/subscribe.svg'


const Download = () => {
    return (
        <div>
            <img src = {downloadbase} className = "downloadbase"></img>
            <img src = {baseThree} className = "downloadPhone"></img>
            <img src = {subscribe} className = "subscribe"></img>
        </div>
    )}

    export default Download