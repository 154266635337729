import React from 'react'
import './Testimonials.css'
import testimonials from '../../assets/testimonials.svg'

const Testimonials = () => {
    return (
        <div>
            <img src = {testimonials} className = "testimonials"></img>
        </div>
    )
}

export default Testimonials