import React from 'react'
import { AppStoreButton, GooglePlayButton } from '../../components'
import './DownloadButtons.css'

const DownloadButtons = () => {
    return (
        <div className='DownloadButtons'>
            <div className = "AppOne">
                <AppStoreButton />
            </div>
            <div className = "GoogleOne">
                <GooglePlayButton />
            </div>
            <div className = "AppTwo">
                <AppStoreButton />
            </div>
            <div className = "GoogleTwo">
                <GooglePlayButton />
            </div>
        </div>
    )               
}

export default DownloadButtons