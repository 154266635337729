import './App.css';
import React, { useEffect } from 'react'
import { AppStore, Background, PlayStore } from './components';
import SubscribeButton from './components/SubscribeButton/SubscribeButton';
import { LearnMore, Values, DownloadButtons, Home, AboutUs, Process, Testimonials, Services, Download, Footer } from './containers';

function App() {
  useEffect(() => {
    document.title = "BASE"
  }, [])
  return (
    <div className="App">
      <Home/>
      <AboutUs/>
      <LearnMore/>
      <Values />
      <Testimonials/>
      <Services/>
      <SubscribeButton />
      <DownloadButtons />
      <Download />
      <Process/>
      <Footer/>
    </div>
  );
}

export default App;
