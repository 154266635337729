import React from 'react'
import './Process.css'
import download from '../../assets/Heading.svg'
import semi from '../../assets/Semi3.svg'
import head from '../../assets/Process.svg'
import { AppStore, PlayStore } from '../../components'

const Process = () => {
    return (
        <div>
            <img src = {download} className = "download"></img>
            <AppStore />
            <PlayStore />
            <img src = {semi} className = "semi"></img>
            <img src = {head} className = "head"></img>
        </div>
    )
    }

export default Process