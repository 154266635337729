import React from 'react'
import './AppStoreButton.css'


const AppStoreButton = () => {
    const onClick = () => {
        console.log("link")
    }
    
    return (
        <div>

            <a href = "https://www.apple.com/app-store/">
                <button className='AppStoreButton' ></button>
            </a>
            

        </div>
    )
}

export default AppStoreButton